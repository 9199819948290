import {
    Typography,
    Box,
    useTheme,
} from "@mui/material";


export default function NavItem({ item, onClick, currentPath }) {
    const theme = useTheme();
    const primaryLight = theme.palette.primary.light;

    const isActive = () => {
        if (item.link === "/" && currentPath === "/") {
            return true
        }

        return currentPath.startsWith("/" + item.link)
    }

    return (
        <Box
            onClick={onClick}
            fontFamily="Rubik"
            sx={{
                fontFamily: "Rubik",
                "&:hover": {
                    cursor: "pointer"
                }
            }}>
            <Typography
                fontFamily="Rubik"
                fontSize="clamp(1rem, 1.2rem, 2rem)"
                color={isActive() ?  primaryLight : "#00D5FA"}
                sx={{
                    "&:hover": {
                        color:  primaryLight,
                        cursor: "pointer"
                    }
                }}
            >
                {item.title}
            </Typography>
        </Box>
    );
 }