import React from "react";
import { Box, Typography, useMediaQuery, Button } from "@mui/material";
import TextButton from "components/shared/ui-lib/text-button";

import hero from "images/hero.png"
import { useAppNavigation } from "hooks/use-app-navigation";

export const HeroSection = () => {
    const isMobile = useMediaQuery("(max-width:960px)");
    const { navigateTo } = useAppNavigation();

    return (
        <Box
            marginTop={isMobile ? '0rem' : '2.5rem'}
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            justifyContent="space-between"
        >
            <Box
                display={'flex'}
                flexDirection={'column'}
                textAlign={'left'}
                alignSelf={'center'}
                maxWidth={isMobile ? "100%" : "40%"}
                mb={isMobile ? "2rem" : "0"}
                marginTop={isMobile ? "2rem" : "0"}
            >
                <Typography
                    variant={isMobile ? "h4" : "h2"}
                    fontWeight="bold"
                    gutterBottom
                    fontSize={'3rem'}
                    fontFamily="Rubik, sans-serif"
                    color={"#006B7D"}
                    textAlign={'left'}
                >
                    ChooseMeSports
                </Typography>
                <Typography
                    fontFamily="Rubik, sans-serif"
                    sx={{ color: "#000000" }}
                    textAlign={'left'}
                    fontSize={'22px'}
                >
                    Discover extraordinary athletic talents from around the world and connect with coaches and scouts.
                </Typography>
                {isMobile && <Box
                    maxWidth={isMobile ? "100%" : "55%"}
                    display="flex"
                    marginTop={'1rem'}
                >
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/choosemesports-ae4e0.appspot.com/o/App%2FGroup%201.png?alt=media&token=2609b127-9230-4845-890f-ba2c5998a635"
                        alt="Hero"
                        style={{ width: "100%", borderRadius: isMobile ? "0" : "10px" }}
                    />
                </Box>}
                <Box display={'flex'} gap={isMobile ? '0.5rem' : '1rem'} flexDirection={isMobile ? 'column' : 'row'}>
                    <Box marginTop={'2rem'} flexDirection={'row'}>
                        <TextButton color={'#006B7D'} onClick={() => window.open("https://choosemeapp.netlify.app", "_blank")} label={'Join Now'} labelColor={'#FFF'} />
                    </Box>
                    <Box marginTop={isMobile ? '0.5rem' : '2rem'} maxWidth={'12rem'} flexDirection={'row'}>
                        <TextButton onClick={() => navigateTo('cards')} label={'Browse Talent'} labelColor={'#006B7D'} />
                    </Box>
                </Box>
            </Box>
            {!isMobile && <Box
                maxWidth={isMobile ? "100%" : "55%"}
                display="flex"
            >
                <img
                    src="https://firebasestorage.googleapis.com/v0/b/choosemesports-ae4e0.appspot.com/o/App%2FGroup%201.png?alt=media&token=2609b127-9230-4845-890f-ba2c5998a635"
                    alt="Hero"
                    style={{ width: "100%", borderRadius: isMobile ? "0" : "10px" }}
                />
            </Box>}
        </Box>
    )
}