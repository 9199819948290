import React from "react";
import { Box, useMediaQuery } from "@mui/material";

export default function Divider({ color="light"}) {
    const isMobile = useMediaQuery("(max-width:960px)");
    return (
        <Box
            margin={isMobile ? '1rem 1.5rem' : '2rem 1.5rem'}
            sx={{
                borderTop: "1.5px solid",
                borderColor: color === "light" ? "#DFE2E8" : "#000"
            }}
        />
    );
}
