import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import Divider from "components/shared/ui-lib/Divider";
import { HeroSection } from "./sections/hero";
import { EmbarkSection } from "./sections/embark";
import { DiscoverSection } from "./sections/discover";
import { ReviewSection } from "./sections/review";
import { FAQ } from "./sections/faq";
import ContactUsSection from "components/Contact";

export default function HomeScreen() {
    const isMobile = useMediaQuery("(max-width:960px)");
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            padding={!isMobile ? "8rem 15rem" : "3rem 1rem"}
            gap={'2rem'}
        >
            <HeroSection />
            <EmbarkSection />
            <Divider />
            <DiscoverSection />
            <ReviewSection />
            <Divider />
            <FAQ />
            <ContactUsSection />
        </Box>
    )
}


