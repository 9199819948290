import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Typography, Alert } from '@mui/material';

import { useAuth } from 'contexts/AuthContext';
import { useApp } from 'contexts/AppContext';

const AuthModal = ({ isOpen, onClose }) => {
    const [isRegister, setIsRegister] = useState(false);
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [categories, setCategories] = React.useState([]);
    const [selectedCategory, setSelectedCategory] = React.useState('');
    const [selectedAgeGroup, setSelectedAgeGroup] = useState('');

    const { signUp, signUserIn } = useAuth()
    const { getCategories } = useApp()

    const ageGroups = ['5-10', '11-15', '15-20', '21-24', '25-30'];

    React.useEffect(() => {
        const fetchData = async () => {
            const categoriesData = await getCategories();
            const sortedCategories = categoriesData.sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;
            });

            setCategories(sortedCategories);
        };
        fetchData();
    }, [isOpen]);

    React.useEffect(() => {
        if (!isOpen) {
            setEmail('');
            setPassword('');
            setConfirmPassword('');
            setFullName('');
            setError('');
            setIsRegister(false);
            setSelectedCategory('')
            setSelectedAgeGroup('')
        }
    }, [isOpen]);

    const toggleIsRegister = () => {
        setIsRegister(!isRegister);
    };

    const handleRegisterSubmit = (event) => {
        event.preventDefault();
        setError('');

        if (password !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        signUp(email, password, userName, fullName, selectedCategory, selectedAgeGroup);
        onClose()
    };

    const handleLoginSubmit = async (event) => {
        event.preventDefault();
        await signUserIn(email, password);
        onClose()
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                {error && (
                    <Alert severity="error" sx={{ marginBottom: 2 }}>{error}</Alert>
                )}
                {isRegister ? (
                    <form onSubmit={handleRegisterSubmit}>
                        <Typography variant="h6" gutterBottom>Create an account</Typography>
                        <TextField
                            fullWidth
                            required
                            label="Full Name"
                            margin="normal"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            required
                            label="Username "
                            margin="normal"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            required
                            label="Email"
                            type="email"
                            margin="normal"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            required
                            label="Password"
                            type="password"
                            margin="normal"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            required
                            label="Confirm Password"
                            type="password"
                            margin="normal"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <select
                            className="custom-dropdown"
                            value={selectedCategory}
                            onChange={e => setSelectedCategory(e.target.value)}
                        >
                            <option value="" disabled>Select a category</option>
                            {categories.map(category => (
                                <option key={category.title} value={category.title}>
                                    {category.title}
                                </option>
                            ))}
                        </select>
                        <select
                            className="custom-dropdown"
                            value={selectedAgeGroup}
                            onChange={e => setSelectedAgeGroup(e.target.value)}
                        >
                            <option value="" disabled>Select Age Group</option>
                            {ageGroups.map(ageGroup => (
                                <option key={ageGroup} value={ageGroup}>
                                    {ageGroup}
                                </option>
                            ))}
                        </select>

                        <Button fullWidth
                            required variant="contained" color="primary" type="submit" style={{ marginTop: '16px' }}>
                            Register
                        </Button>
                        <Typography variant="body2" style={{ marginTop: '16px', textAlign: 'center' }}>
                            Already have an account? <a href="#!" onClick={(e) => {
                                e.preventDefault();
                                toggleIsRegister();
                            }}>Login</a>
                        </Typography>
                    </form>
                ) : (
                    <form onSubmit={handleLoginSubmit}>
                        <Typography variant="h6" gutterBottom>Log in to your account</Typography>
                        <TextField
                            fullWidth
                            required
                            label="Email"
                            type="email"
                            margin="normal"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            required
                            label="Password"
                            type="password"
                            margin="normal"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button fullWidth
                            required variant="contained" color="primary" type="submit" style={{ marginTop: '16px' }}>
                            Login
                        </Button>
                        <Typography variant="body2" style={{ marginTop: '16px', textAlign: 'center' }}>
                            Don't have an account? <a href="#!" onClick={(e) => {
                                e.preventDefault();
                                toggleIsRegister();
                            }}>Register</a>
                        </Typography>
                    </form>
                )}
            </Box>
        </Modal>
    );
};

export default AuthModal;
