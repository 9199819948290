import React, { useState } from 'react'
import { collection, query, where, onSnapshot, getDocs } from "firebase/firestore";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject
} from "@firebase/storage";

import { db } from "../config"

export const useVideosContext = () => {
    const [videos, setVideos] = useState([])

    React.useEffect(() => {
        const fetchData = async () => { 
            const q = query(collection(db, "Videos"));
            onSnapshot(q, (querySnapshot) => {
                const articles = [];
                querySnapshot.forEach((doc) => {
                    articles.push({id: doc.id, ...doc.data()});
                });
                setVideos(articles)
            });
        }
        fetchData()
    }, [])


    const getVideoById = async (videoID) => {
        console.log(videoID)
        const videoCollection = collection(db, "Videos");
        const q = query(videoCollection, where("id", "==", videoID));

        try {
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const videoDoc = querySnapshot.docs[0];
                const videoData = videoDoc.data();
                const docId = videoDoc.id;

                return { id: docId, ...videoData };
            } else {
                console.log("No video with this ID found");
                return null;
            }
        } catch (error) {
            console.error("Error fetching video:", error);
            return null;
        }
    };

    return { videos, getVideoById }
}
