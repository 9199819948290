import React from "react";
import {
  Box,
  Typography,
  Avatar,
  Rating,
} from "@mui/material";

const VideoCard = ({ videoSrc, profilePic, userName, avgRating, numReviews, onVideoClick, posterSrc, category }) => (
  <Box
    onClick={() => onVideoClick(videoSrc)}
    style={{
      cursor: 'pointer',
      marginBottom: "1rem",
    }}
    padding='1rem'
  >
    {/* Video Thumbnail */}
    <img
      src={posterSrc}
      alt={userName + "'s video"}
      style={{ width: "100%", height: "11.25rem", objectFit: "cover", backgroundColor: '#FFFFFF', borderRadius: '8px' }}  
    />
    <Box display="flex" mt="0.5rem" padding="0rem 0.5rem">
      <Avatar src={profilePic} />
      <Box style={{ marginLeft: "0.5rem" }}>
        <Typography>
          {userName}
        </Typography>
        <Typography variant="body2">
          {category}
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default VideoCard;
