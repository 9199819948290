import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { Tune } from "@mui/icons-material";
import VideoCard from "components/shared/cards/VideoCard";
import AdCard from "components/shared/cards/AdCard";
import FlexBetween from "components/FlexBetween";
import { useApp } from 'contexts/AppContext';
import { adData } from "dummData";
import { CategoryModal } from 'components/shared/modals/CategoryModal';
import { AgeGroupModal } from 'components/shared/modals/AgeGroupModal';
import { useVideosContext } from 'contexts/use-video-context';

import { useNavigate } from 'react-router-dom';


const VideosScreen = () => {
  const { videos } = useVideosContext()
  const isMobile = useMediaQuery("(max-width: 1000px)");
  const theme = useTheme();
  const { getCategories } = useApp();
  const alt = theme.palette.background.alt;
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isAgeModalOpen, setIsAgeModalOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All Categories');
  const [selectedAgeGroup, setSelectedAgeGroup] = useState('All Ages');
  const navigate = useNavigate()


  const handleSetCategory = (category) => {
    setSelectedCategory(category);
  };

  const updateSelectedAgeGroups = (ageGroups) => {
    setSelectedAgeGroup(ageGroups);
  };

  useEffect(() => {
    let updatedVideos = videos;
  
    if (selectedCategory !== 'All Categories') {
      updatedVideos = updatedVideos.filter(video => video.Category === selectedCategory);
    }
  
    if (selectedAgeGroup !== 'All Ages') {
      updatedVideos = updatedVideos.filter(video => video.ageGroup === selectedAgeGroup);
    }
  
    setFilteredVideos(updatedVideos);
  }, [videos, selectedCategory, selectedAgeGroup]);

  useEffect(() => {
    const fetchData = async () => {
      const categoriesData = await getCategories();
      const sortedCategories = categoriesData.sort((a, b) => {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    });

    setCategories(sortedCategories);
    };
    fetchData();
  }, []);


  useEffect(() => {
    if (isAgeModalOpen || isCategoryModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isAgeModalOpen, isCategoryModalOpen]);

  const handleCategoryModalToggle = () => {
    setIsCategoryModalOpen(!isCategoryModalOpen);
  };

  const handleAgeModalToggle = () => {
    setIsAgeModalOpen(!isAgeModalOpen);
  };

  return (
    <Box>
      <FlexBetween
        padding="1.6rem 8%"
        backgroundColor={alt}
        alignItems="center"
        style={{
          position: "fixed",
          top: isMobile ? 55 : 80,
          left: 0,
          width: "100%",
          zIndex: 90
        }}
      >
        <Box>
          <Button
            startIcon={<Tune />}
            variant="contained"
            color="primary"
            onClick={handleCategoryModalToggle}
            sx={{ borderRadius: '9px', marginRight: '1rem' }}
          >
            {selectedCategory}
          </Button>

          <Button
            startIcon={<Tune />}
            variant="contained"
            color="primary"
            onClick={handleAgeModalToggle}
            sx={{ borderRadius: '9px', marginRight: '1rem' }}
          >
            {selectedAgeGroup}
          </Button>
        </Box>

        {isCategoryModalOpen && (
          <CategoryModal
            onClose={handleCategoryModalToggle}
            categories={categories}
            updateSelectedCategories={handleSetCategory}
            selectedCategory={selectedCategory}
          />
        )}

        {isAgeModalOpen && (
          <AgeGroupModal
            onClose={handleAgeModalToggle}
            updateSelectedAgeGroups={updateSelectedAgeGroups}
            selectedAgeGroup={selectedAgeGroup}
          />
        )}
      </FlexBetween>

      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        padding="0.5rem 6%"
        marginTop="9rem"
      >
        <Box flex={isMobile ? "1" : "3"} pr={isMobile ? "0" : "1rem"}>
          {
            filteredVideos.length < 1 ? (
              <h4>Nothing to show for {selectedCategory} and {selectedAgeGroup}</h4>
            ) : (
              <Box display="flex" flexWrap="wrap" justifyContent="space-between">
              {filteredVideos.map(video =>
                <Box
                  key={video.id}
                  width={isMobile ? "100%" : "25%"}
                  marginBottom={isMobile ? '0rem' : "1rem"}
                >
                  <VideoCard
                    category={video.Category}
                    numReviews={10}
                    videoSrc={video.videoSrc}
                    profilePic={video.profilePic}
                    userName={video.userName}
                    avgRating={video.rating}
                    posterSrc={video.poster}
                    onVideoClick={() => navigate(`/videos/${video.id}`)}
                  />
                </Box>
              )}
            </Box>
            )
          }
        </Box>

        {!isMobile &&
          <Box flex="1" pl="1rem">
            {adData.map(ad =>
              <AdCard key={ad.id} adImageSrc={ad.adImageSrc} />
            )}
          </Box>}
      </Box>
    </Box>
  );
};

export default VideosScreen;
