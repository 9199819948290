import React from "react";
import { Box, Typography, useMediaQuery, Button, Grid, Paper } from "@mui/material";
import { useAppNavigation } from "hooks/use-app-navigation";

const steps = [
    { title: 'Showcase Your Talent', description: 'Upload videos showcasing your unique athletic abilities and skills.' },
    { title: 'Impress Coaches', description: 'Catch the attention of top coaches and scouts looking for the next sports stars.' },
    { title: 'Ignite Your Career', description: 'Leverage the exposure and the ratings you receive on our platform to ignite your career.' },
];

export const EmbarkSection = () => {
    const isMobile = useMediaQuery("(max-width:960px)");
    return (
        <Box marginTop={isMobile ? '2rem' : '5rem'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography
                variant={isMobile ? "h4" : "h2"}
                gutterBottom
                fontSize={isMobile ? '1.5rem' : '3rem'}
                fontFamily="Rubik, sans-serif"
                color={'#00A0BC'}
            >
                Embark on a Journey Towards Your Dream
            </Typography>

            <Grid container spacing={isMobile ? 1 : 3} marginTop={isMobile ? '1rem' : "2rem"} direction={isMobile ? "column" : "row"}>
                {steps.map((step, index) => (
                    <Grid item xs={8} sm={3} md={4} key={index}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                padding: isMobile ? '1.5rem 1rem' : '2rem 1.5rem',
                                backgroundColor: 'transparent',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                backgroundColor: '#FFF',
                                borderRadius: '12px',
                            }}
                        >
                            <Typography
                                fontSize="26px"
                                fontFamily="Rubik"
                                style={{
                                    marginTop: isMobile ? '0rem' : '1.5rem',
                                    marginBottom: '0.5rem',
                                }}
                            >
                                {step.title}
                            </Typography>
                            <Typography
                                fontFamily="Rubik"
                                fontSize="20px"
                                color="#7A788E"
                                style={{
                                    flexGrow: 1
                                }}
                            >
                                {step.description}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}


