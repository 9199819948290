import { CardMedia, Typography, Rating, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PlayerCard = ({ Name, Category, Nationality, dp, sub }) => {
    const isMobile = useMediaQuery("(max-width: 1000px)");
    const navigate = useNavigate()
    const countryCode = 'RU'

    const flag = countryCode.toUpperCase().replace(/./g, char =>
        String.fromCodePoint(127397 + char.charCodeAt())
    );

    return (
        <div className="player-card" onClick={() => navigate(`/profile/${sub}`)} >
            <div className="player-header">
                <Typography color={"#000"} gutterBottom variant="h4" component="div" noWrap>{Name.split(' ')[0]}</Typography>
            </div>
            <div className="player-image">
                <CardMedia
                    component="img"
                    sx={{
                        height: isMobile ? '100px' : '200px',
                        objectFit: 'contain',
                        p: 1,
                    }}
                    image={dp || "https://vdostavka.ru/wp-content/uploads/2019/05/no-avatar.png"}
                    alt={Name || "Default name"}
                />
            </div>
            <div className="player-stats">
                <div className='flag-container'>
                    <span className='flag'>{flag}</span>
                </div>
                <div className="player-country">{Category}</div>
                <div className="player-rating">
                    <Rating
                        name={"video-" + Name}
                        value={4}
                        readOnly
                        precision={0.5}
                        style={{ marginLeft: "-0.1rem" }}
                    />
                </div>
            </div>
        </div>
    );
};

export default PlayerCard;
