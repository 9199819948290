import { Box, Grid, Button, useMediaQuery, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import { CategoryModal } from 'components/shared/modals/CategoryModal';
import { AgeGroupModal } from 'components/shared/modals/AgeGroupModal';
import { Tune } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";

import { useApp } from 'contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import PlayerCard from './ui/PlayerCard';

const CardsScreen = () => {
    const isMobile = useMediaQuery("(max-width: 1000px)");
    const { getUsers, getCategories } = useApp()
    const theme = useTheme();
    const alt = theme.palette.background.alt;
    const [users, setUsers] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All Categories');
    const [selectedAgeGroup, setSelectedAgeGroup] = useState('All Ages');
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
    const [isAgeModalOpen, setIsAgeModalOpen] = useState(false);
    const [categories, setCategories] = useState([]);

    const handleSetCategory = (category) => {
        setSelectedCategory(category);
    };

    const updateSelectedAgeGroups = (ageGroups) => {
        setSelectedAgeGroup(ageGroups);
    };

    const handleCategoryModalToggle = () => {
        setIsCategoryModalOpen(!isCategoryModalOpen);
    };

    const handleAgeModalToggle = () => {
        setIsAgeModalOpen(!isAgeModalOpen);
    };

    useEffect(() => {
        let updatedUsers = users;

        if (selectedCategory !== 'All Categories') {
            updatedUsers = updatedUsers.filter(user => user.Category === selectedCategory);
        }

        if (selectedAgeGroup !== 'All Ages') {
            updatedUsers = updatedUsers.filter(user => user.ageGroup === selectedAgeGroup);
        }

        setFilteredUsers(updatedUsers);
    }, [users, selectedCategory, selectedAgeGroup]);

    useEffect(() => {
        const fetchData = async () => {
            const categoriesData = await getCategories();
            const usersData = await getUsers();
            if (usersData) {
                setUsers(usersData);
            }
            if (categoriesData) {
                setCategories(categoriesData);
            }
        };
        fetchData();
    }, []);

    return (
        <Box display={'flex'} flexDirection={'column'} padding={!isMobile ? "8rem 12.5rem" : "3rem 1rem"}>
            <FlexBetween
                padding={isMobile ?  "1rem 0rem" : "3.6rem 0rem" }
                backgroundColor={alt}
                alignItems="center"
                style={{
                    position: "fixed",
                    top: isMobile ? 55 : 80,
                    left: isMobile ? '1rem' : '13rem',
                    width: "100%",
                    zIndex: 90
                }}
            >
                <Box>
                    <Button
                        startIcon={<Tune />}
                        variant="contained"
                        color="primary"
                        onClick={handleCategoryModalToggle}
                        sx={{ borderRadius: '9px', marginRight: '1rem', }}
                    >
                        {selectedCategory}
                    </Button>

                    <Button
                        startIcon={<Tune />}
                        variant="contained"
                        color="primary"
                        onClick={handleAgeModalToggle}
                        sx={{ borderRadius: '9px', marginRight: '1rem' }}
                    >
                        {selectedAgeGroup}
                    </Button>
                </Box>

                {isCategoryModalOpen && (
                    <CategoryModal
                        onClose={handleCategoryModalToggle}
                        categories={categories}
                        updateSelectedCategories={handleSetCategory}
                        selectedCategory={selectedCategory}
                    />
                )}

                {isAgeModalOpen && (
                    <AgeGroupModal
                        onClose={handleAgeModalToggle}
                        updateSelectedAgeGroups={updateSelectedAgeGroups}
                        selectedAgeGroup={selectedAgeGroup}
                    />
                )}
            </FlexBetween>
                <Box marginTop={isMobile ? "3rem" : "6rem" } overflow={'hidden'} display={'flex'} alignItems={'center'}>
                    {
                        filteredUsers.length < 1 ? (
                            <h4 marginTop="40rem">Nothing to show for {selectedCategory} and {selectedAgeGroup}</h4>
                        ) : (
                            <Box sx={{ pt: '50px', px: 3, pb: 3 }} display={'flex'} alignItems={'center'}>
                                <Grid display={'flex'} container spacing={isMobile ? 4 : 3} gap={'0.1rem'} alignSelf={'center'}>
                                    {filteredUsers.map((user, index) => (
                                         <PlayerCard key={index} {...user} />
                                    ))}
                                </Grid>
                            </Box>
                        )
                    }
                </Box>
        </Box>
    );
};

export default CardsScreen;