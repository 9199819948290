import React from "react";
import { Box, Typography, useMediaQuery, Button, Grid, Paper } from "@mui/material";
import browse from "images/browse_talent.png"


const discover = [
    { title: 'Diverse Sports', description: 'Explore a wide range of sports disciplines, from traditional to emerging talents.' },
    { title: 'Rising Stars', description: 'Discover the next generation of athletes pushing the boundaries of their sport.' },
    { title: 'Inspiring Journeys', description: 'Learn about the personal stories and motivations behind each talented performer.' },
];

export const DiscoverSection = () => {
    const isMobile = useMediaQuery("(max-width:960px)");
    return (
            <Box marginTop={isMobile ? '2rem' : '5rem'} display={'flex'} flexDirection={isMobile ? 'column-reverse' : 'row'} alignItems={'center'}>
                    <Box
                        width={isMobile ? "60%" : "50%"}
                        height={isMobile ? '400px' : '500px'}
                        display="flex"
                        alignSelf={isMobile ? 'center':'flex-end'}
                    >
                        <img
                            src={browse}
                            alt="browse talent"
                            style={{ width: "100%", borderRadius: isMobile ? "0" : "10px" }}
                        />
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        textAlign={'left'}
                        alignSelf={'center'}
                        maxWidth={isMobile ? "100%" : "65%"}
                        mb={isMobile ? "2rem" : "0"}
                        marginLeft={!isMobile ? '5rem' : undefined}
                    >
                       <Typography
                            variant={isMobile ? "h4" : "h2"}
                            gutterBottom
                            fontSize={isMobile ? '1.5rem' : '3rem'}
                            fontFamily="Rubik, sans-serif"
                            color={'#00A0BC'}
                            marginBottom={isMobile ? '2.5rem' : undefined}
                        >
                            Discover Extraordinary Talents
                        </Typography>
                        <Grid container spacing={isMobile ? 1 : 2} direction={isMobile ? "column" : "row"}>
                            {discover.map((step, index) => (
                                <Grid item xs={6} sm={2} md={5} key={index}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            padding: '0rem',
                                            backgroundColor: 'transparent',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%',
                                            backgroundColor: '#FFF',
                                            borderRadius: '12px',
                                        }}
                                        marginBottom={'1rem'}
                                    >
                                        <Typography
                                            fontSize={isMobile ? "22px" : "26px"}
                                            fontFamily="Rubik"
                                            style={{
                                                marginBottom: '0.5rem',
                                            }}
                                        >
                                            {step.title}
                                        </Typography>
                                        <Typography
                                            fontFamily="Rubik"
                                            fontSize={isMobile ? "18px" : "20px"}
                                            color="#7A788E"
                                            style={{
                                                flexGrow: 1
                                            }}
                                        >
                                            {step.description}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
    )
}


