import React from 'react'
import { Box, Typography, Link } from "@mui/material";

export default function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <Box
            bgcolor={'#00353F'}
            sx={{
                width: '100%',
                height: '5rem',
                backgroundColor: '#00353F',
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '1rem',
            }}
        >
            <Typography variant="body1">
                Copyright © ChooseMeSports {currentYear}
            </Typography>
            <Typography variant="body1">
                Designed by{" "}
                <Link
                    href="https://www.softsavvy.ng"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="inherit"
                    underline="hover"
                >
                    SoftSavvy
                </Link>
            </Typography>
        </Box>
    )
}
