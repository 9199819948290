import React, { useState } from "react";
import NavItem from "./NavItem";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppNavigation } from "../../../hooks/use-app-navigation";

export default function NavLinks(props) {
    const {navigateTo} = useAppNavigation()
    const location = useLocation()
    const currentPath = location.pathname;

    const handleOnPress = (item) => {
        if(props.toggleMenu){
            props.toggleMenu()
        }
        navigateTo(item.link)
    }

    const navLinks = [ 
        { title: "Home", link: "/" }, 
        { title: "Videos", link: "videos" }, 
        { title: "Profiles", link: "cards" }, 
        // { title: "About Us", link: "videos" }, 
        // { title: "Contact", link: "cards" }, 
    ]
    return (
        navLinks.map((item) => (
            <NavItem item={item} onClick={() => handleOnPress(item)} currentPath={currentPath}/>
        ))
    );
}