import React from "react";
import { Card, CardMedia, Typography } from "@mui/material";

const AdCard = ({ adImageSrc }) =>
  <Card
    style={{
      borderRadius: "15px",
      backgroundColor: "#f5f5f5",
      marginBottom: "1rem"
    }}
  >
    <Typography style={{ position: 'absolute', }}>Ad</Typography>
    <CardMedia component="img" src={adImageSrc} style={{ height: 200, objectFit: "contain" }} />
  </Card>;

export default AdCard;
