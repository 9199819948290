import React, { useState } from "react";
import { Box, useMediaQuery, Typography, Rating } from "@mui/material";
import UserInfo from "components/UserInfo";
import { useAuth } from "contexts/AuthContext";
import { useApp } from "contexts/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import EditProfile from "components/shared/modals/EditProfile";
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddVideo from "components/shared/modals/AddVideo";

const ProfileScreen = () => {
    const { userID } = useParams();
    const { signUserOut } = useAuth();
    const { getUserById, getVideosByUserId } = useApp();
    const { authUserID } = useAuth()
    const navigate = useNavigate();
    const isMobileScreen = useMediaQuery("(max-width: 1022px");
    const [isEditProfile, setIsEditProfile] = useState(false);
    const [isAddVideo, setIsAddVideo] = useState(false);

    const [user, setUser] = React.useState(null);
    const [userVideos, setUserVideos] = React.useState([])

    const isMine = authUserID === userID;

    const handleVideoClick = (video) => {
        navigate(`/videos/${video.id}`)
    };

    React.useEffect(() => {
        const fetchData = async () => {
            const userData = await getUserById(userID);
            if (userData) {
                setUser(userData);

                const userVideos = await getVideosByUserId(userID)

                setUserVideos(userVideos)
            }
        };
        fetchData();
    }, [userID]);

    const noAvatar = "https://vdostavka.ru/wp-content/uploads/2019/05/no-avatar.png"
    return (
        <Box
            className="body"
            alignItems="center"
            display="flex"
            flexDirection="column"
        >
            <Box
                maxWidth={isMobileScreen ? "100%" : "45%"}
                padding="6.5rem 1.5rem"
                bgcolor="#D3D3D3"
            >
                <Box
                    display="flex"
                    gap="2.5rem"
                    height="100%"
                    flexDirection={isMobileScreen ? "column" : "row"}
                >
                    <div>
                        <img
                            src={user?.dp || noAvatar}
                            alt="Asusoft"
                            style={{
                                height: "13rem",
                                objectFit: "contain",
                                backgroundColor: "#FFFFFF",
                                borderRadius: "8px"
                            }}
                        />
                    </div>
                    <Box height="100%">
                        <Box bgcolor="purple" borderRadius="6px" padding="0.5rem">
                            <h4 style={{ margin: "0", color: "#FFF" }}>
                                PERSONAL INFORMATION
                            </h4>
                        </Box>
                        <Box flexDirection="column" marginTop="0.5rem">
                            <div className="infoBox">
                                <p className="infoLabel">Name</p>
                                <p className="infoData">{user?.Name}</p>
                            </div>

                            <div className="infoBox">
                                <p className="infoLabel">Category:</p>
                                <p className="infoData">{user?.Category}</p>
                            </div>

                            <div className="infoBox">
                                <p className="infoLabel">Nationality:</p>
                                <p className="infoData">{user?.Nationality}</p>
                            </div>
                            <div className="infoBox">
                                <p className="infoLabel">Date of Birth:</p>
                                {user && <p className="infoData">{user["Date of Birth"]}</p>}
                            </div>
                            <div className="infoBox">
                                <p className="infoLabel">Place of Birth:</p>
                                {user && <p className="infoData">{user["Place of Birth"]}</p>}
                            </div>
                            <div className="infoBox">
                                <p className="infoLabel">Height:</p>
                                <p className="infoData">{user?.Height}</p>
                            </div>
                            <div className="infoBox">
                                <p className="infoLabel">Weight:</p>
                                <p className="infoData">{user?.Weight}</p>
                            </div>
                            {
                                user?.Category === "Football" && (
                                    <>
                                        <div className="infoBox">
                                            <p className="infoLabel">Position:</p>
                                            <p className="infoData">{user["Position"]}</p>
                                        </div>
                                        <div className="infoBox">
                                            <p className="infoLabel">Strong Leg:</p>
                                            <p className="infoData">{user["Strong Leg"]}</p>
                                        </div>
                                    </>
                                )
                            }
                        </Box>
                        {
                            isMine && (
                                <>
                                <Box
                                    onClick={() => setIsEditProfile(true)}
                                    bgcolor="purple"
                                    borderRadius="6px"
                                    padding="0.5rem"
                                    marginTop="1.5rem"
                                >
                                    <h4 style={{ margin: "0", color: "#FFF" }}>EDIT PROFILE</h4>
                                </Box>
                                <Box
                                    onClick={() => {
                                        signUserOut();
                                        navigate("/");
                                    }}
                                    bgcolor="purple"
                                    borderRadius="6px"
                                    padding="0.5rem"
                                    marginTop="1.5rem"
                                >
                                    <h4 style={{ margin: "0", color: "#FFF" }}>SIGN OUT</h4>
                                </Box>
                                </>
                            )
                        }
                    </Box>
                </Box>

                <Box
                    marginTop="2.5rem"
                    bgcolor="#FFF"
                    borderRadius="8px"
                    padding="1rem"
                >
                    <p style={{ margin: "0" }}>
                        {user?.Description}
                    </p>
                </Box>
                <Box
                    marginTop="2.5rem"
                    bgcolor="#FFF"
                    borderRadius="8px"
                    padding="1rem"
                >
                    <Box bgcolor="purple" borderRadius="6px" padding="0.5rem 1.5rem">
                        <h4 style={{ margin: "0", color: "#FFF" }}>SKILLS RATING</h4>
                    </Box>
                    <Box height="100%" flexDirection="column" padding="1.5rem" gap="3rem">
                        {user?.skills && Object.entries(user?.skills).map(([key, value], idx) => {
                            if (key !== "id") {
                                return (
                                    <UserInfo
                                        key={idx}
                                        label={`${key.toUpperCase()}:`}
                                        info={value}
                                    />
                                );
                            } else {
                                return null;
                            }
                        })}
                    </Box>
                </Box>
                <Box
                    marginTop="2.5rem"
                    bgcolor="#FFF"
                    borderRadius="8px"
                    padding="1rem"
                >
                    <Box bgcolor="purple" borderRadius="6px" padding="0.5rem 1.5rem">
                        <h4 style={{ margin: "0", color: "#FFF" }}>INJURY HISTORY</h4>
                    </Box>
                    {user && <p>
                        {user['Injury history']}
                    </p>}
                </Box>
                <Box
                    marginTop="2.5rem"
                    bgcolor="#FFF"
                    borderRadius="8px"
                    padding="1rem"
                >
                    <Box bgcolor="purple" borderRadius="6px" padding="0.5rem 1.5rem" display="flex" justifyContent="space-between">
                        <h4 style={{ margin: "0", color: "#FFF" }}>VIDEOS</h4>
                        {  isMine && (<h4  onClick={() => setIsAddVideo(true)} style={{ margin: "0", color: "#FFF" }}>UPLOAD VIDEO</h4>)}
                    </Box>
                    {
                        userVideos.length != 0 ? (
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
                                    gridGap: '2px',
                                    marginTop: '1rem',
                                }}
                            >
                                {userVideos.map((video) => (
                                    <Box
                                        key={video.id}
                                        sx={{
                                            position: 'relative',
                                            width: '100%',
                                            paddingBottom: '100%', 
                                            '&:hover > .overlay': { 
                                                display: 'flex',
                                            },
                                        }}
                                    >
                                        <Box
                                            className="overlay"
                                            sx={{
                                                display: 'none',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                                bottom: 0,
                                                left: 0,
                                                backgroundColor: 'rgba(0, 0, 0, 0.3)', 
                                            }}
                                        >
                                        </Box>
                                        <IconButton
                                            sx={{
                                                position: 'absolute',
                                                top: '4px',
                                                right: '4px',
                                                color: 'common.white',
                                                zIndex: 2, 
                                            }}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                            }}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <img
                                            src={video.poster}
                                            alt={video.title}
                                            onClick={() => handleVideoClick(video)}
                                            style={{
                                                position: 'absolute',
                                                objectFit: 'cover',
                                                width: '100%',
                                                height: '100%',
                                                top: 0,
                                                left: 0,
                                            }}
                                        />
                                    </Box>
                                ))}

                            </Box>

                        ) : (
                            <Box display="flex" width="100%" alignItems="center" justifyContent="center" >
                                <p>{isMine ? "Upload your Videos to see here" : "User has not uplaoded any video yet"}</p>
                            </Box>
                        )
                    }
                </Box>
            </Box>
            <EditProfile isOpen={isEditProfile} onClose={() => setIsEditProfile(false)} user={user} />
            <AddVideo isOpen={isAddVideo} onClose={() => setIsAddVideo(false)} userID={userID} />
        </Box>
    );
};

export default ProfileScreen;
