import React, { createContext, useState, useContext } from "react";
import {
    addDoc,
    collection,
    query,
    where,
    getDocs,
    updateDoc,
    doc
} from "firebase/firestore";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject
} from "@firebase/storage";

import { db } from "../config";

const AppContext = createContext();

const sports = [
    {"title": "Football"},
    {"title": "Basketball"},
    {"title": "Baseball"},
    {"title": "American Football"},
    {"title": "Tennis"},
    {"title": "Golf"},
    {"title": "Cricket"},
    {"title": "Hockey"},
    {"title": "Table Tennis"},
    {"title": "Volleyball"},
    {"title": "Rugby"},
    {"title": "Swimming"},
    {"title": "Track and Field"},
    {"title": "Boxing"},
    {"title": "Mixed Martial Arts"},
    {"title": "Gymnastics"},
    {"title": "Skateboarding"},
    {"title": "Surfing"},
    {"title": "Skiing"},
    {"title": "Snowboarding"}
  ]

export const AppContextProvider = ({ children }) => {

    const deleteFileByUrl = async (fileUrl) => {
        const storage = getStorage();
        const decodedUrl = decodeURIComponent(fileUrl);
        const filePath = decodedUrl.split("/o/")[1].split("?")[0];

        const fileRef = ref(storage, filePath);

        deleteObject(fileRef)
            .then(() => {
                console.log("File successfully deleted");
            })
            .catch((error) => {
                console.error("Error removing file: ", error);
            });
    };

    const extractFileName = (url) => {
        const decodedUrl = decodeURIComponent(url);
        const segments = decodedUrl.split("/");
        const lastSegment = segments.pop();
        const fileName = lastSegment.split("?")[0];

        return fileName;
    };

    const getUserById = async (userID) => {
        const userCollection = collection(db, "Users");
        const q = query(userCollection, where("sub", "==", userID));

        try {
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0];
                const userData = userDoc.data();
                const docId = userDoc.id;

                return { id: docId, ...userData };
            } else {
                console.log("No user with this ID found");
                return null;
            }
        } catch (error) {
            console.error("Error fetching user:", error);
            return null;
        }
    };

    const updateUserProfile = async (userID, updatedFields) => {
        const userDocRef = doc(db, "Users", userID);
        try {
            await updateDoc(userDocRef, updatedFields);
        } catch (error) {
            alert("Error updating user:", error);
        }
    };

    const updateUserProfilePic = async (userID, image, oldDP) => {
        const storage = getStorage();

        const imageRef = ref(storage, `ProfilePhotos/${image.name}`);
        const uploadTask = uploadBytesResumable(imageRef, image);

        await uploadTask;

        const imageUrl = await getDownloadURL(imageRef);

        const updateField = { dp: imageUrl };

        await updateUserProfile(userID, updateField);

        if (
            extractFileName(oldDP) !== extractFileName(imageUrl) &&
            oldDP !== "https://vdostavka.ru/wp-content/uploads/2019/05/no-avatar.png"
        ) {
            await deleteFileByUrl(oldDP);
        }
    };

    const getVideosByUserId = async (userID) => {
        const videoCollection = collection(db, "Videos");
        const q = query(videoCollection, where("userID", "==", userID));

        try {
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const videos = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));

                return videos;
            } else {
                console.log("No videos found for this user ID");
                return [];
            }
        } catch (error) {
            console.error("Error fetching videos:", error);
            return [];
        }
    };

    const uploadVideo = async (userID, cover, videoSource, skill) => {
        const storage = getStorage();
        const user = await getUserById(userID);
        let videoUrl;

        try {
            const coverRef = ref(storage, `VideoCovers/${cover.name}`);
            const uploadCoverTask = uploadBytesResumable(coverRef, cover);
            await uploadCoverTask;
            const coverUrl = await getDownloadURL(coverRef);


            if (videoSource instanceof File) {
                const videoRef = ref(storage, `Videos/${videoSource.name}`);
                const uploadVideoTask = uploadBytesResumable(videoRef, videoSource);
                await uploadVideoTask;
                videoUrl = await getDownloadURL(videoRef);
            } else if (typeof videoSource === 'string') {
                videoUrl = videoSource;
            }

            await addDoc(collection(db, "Videos"), {
                poster: coverUrl,
                profilePic: user.dp,
                userName: user.userName,
                Category: user.Category,
                videoSrc: videoUrl,
                ageGroup: user.ageGroup,
                rating: 0,
                userID: userID,
                skill: skill
            });
        } catch (error) {
            console.error(error.message);
        }

        return videoUrl;
    };

    const getCategories = async () => {
        try {
            const categoriesCollection = collection(db, "Categories");
            const querySnapshot = await getDocs(categoriesCollection);
            const fetchedCategories = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            return fetchedCategories;
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const getVideos = async () => {
        try {
            const videosCollection = collection(db, "Videos");
            const querySnapshot = await getDocs(videosCollection);
            const fetchedVideos = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            return fetchedVideos;
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const getUsers = async () => {
        try {
            const usersCollection = collection(db, "Users");
            const querySnapshot = await getDocs(usersCollection);
            const fetchedUsers = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            return fetchedUsers;
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const contactUs = async (name, email, message) => {
        try {
            await addDoc(collection(db, "Messages"), {
                name: name,
                email: email,
                message: message,
            });
            alert("Message sent successfully. We will get back to you shortly.")
        } catch (error) {
            console.error(error.message);
        }
    };

    const reviewVideo = async (vidId, review, rating) => {
        try {
            await addDoc(collection(db, "Reviews"), {
                vidId: vidId,
                text: review,
                rating: rating,
                reviewer: "Anonymous"
            });
            alert("Thank you for reviewing our video.")
        } catch (error) {
            console.error(error.message);
        }
    };

    function getSkillsBySport(sportName) {
        const sportsSkills = {
            Football: {
                Pace: "",
                Shooting: "",
                Passing: "",
                Dribbling: "",
                Defending: "",
                Physicality: "",
                Teamwork: "",
                BallControll: "",
                Heading: ""
            },
            Basketball: {
                Shooting: "",
                Dribbling: "",
                Passing: "",
                Rebounding: "",
                Defense: "",
                Speed: "",
                Agility: "",
                Stamina: "",
                Jumping: "",
                CourtVision: "",
                Teamwork: ""
            },
            Volleyball: {
                Serving: "",
                Passing: "",
                Setting: "",
                "Hitting Spiking": "",
                Blocking: "",
                Digging: "",
                Jumping: "",
                Agility: "",
                Stamina: ""
            },
            Tennis: {
                Serve: "",
                Forehand: "",
                Backhand: "",
                Volley: "",
                "Overhead Smash": "",
                Footwork: "",
                Stamina: "",
                Endurance: "",
                Consistency: ""
            }
        };

        return sportsSkills[sportName] || {};
    }

    const getVideosSkills = async (sport) => {
        const skillsCollection = collection(db, "Skills");
        const q = query(skillsCollection, where("sport", "==", sport));

        try {
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const skills = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));

                return skills;
            } else {
                console.log("No skills found for this sport");
                return [];
            }
        } catch (error) {
            console.error("Error fetching skills:", error);
            return [];
        }
    };

    return (
        <AppContext.Provider
            value={{
                getCategories,
                getSkillsBySport,
                getUserById,
                updateUserProfile,
                getVideosByUserId,
                updateUserProfilePic,
                getVideos,
                uploadVideo,
                getUsers,
                contactUs,
                reviewVideo,
                getVideosSkills
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useApp = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error("useAuth must be used within an AppContextProvider");
    }
    return context;
};
