import React, { useState } from "react";
import {
    Modal,
    Box,
    TextField,
    Button,
    Typography,
    Alert
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { useAuth } from "contexts/AuthContext";
import { useApp } from "contexts/AppContext";

const EditProfile = ({ isOpen, onClose, user }) => {
    const [nationality, setNationality] = useState(user?.Nationality);
    const [dateOfBirth, setDOB] = useState("");
    const [placeofBirth, setPOB] = useState("");
    const [height, setHeight] = useState(user?.Height);
    const [weight, setWeight] = useState(user?.Weight);
    const [description, setDescription] = useState(user?.Description);
    const [injuryHistory, setInjuryHistory] = useState("");
    const [error, setError] = useState("");
    const [profilePicUrl, setProfilePicUrl] = useState(user?.dp);
    const [position, setPosition] = useState("");
    const [strongLeg, setStrongLeg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [image, setImage] = useState(null);
    const { updateUserProfile, updateUserProfilePic } = useApp();

    const onImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file);
            setProfilePicUrl(URL.createObjectURL(file));
        }
    };

    React.useEffect(() => {
        if (user) {
            setNationality(user?.Nationality);
            setHeight(user?.Height);
            setWeight(user?.Weight);
            setDOB(user["Date of Birth"]);
            setPOB(user["Place of Birth"]);
            setInjuryHistory(user["Injury history"]);
            setDescription(user.Description);
            setProfilePicUrl(user?.dp);

            if (user.Category === "Football") {
                setPosition(user["Position"]);
                setStrongLeg(user["Strong Leg"]);
            }
        }
    }, [user]);

    const updateFields = {
        Nationality: nationality,
        "Date of Birth": dateOfBirth,
        "Place of Birth": placeofBirth,
        Height: height,
        Weight: weight,
        Description: description,
        "Injury history": injuryHistory
    };

    if (user?.Category === "Football") {
        updateFields["Position"] = position;
        updateFields["Strong Leg"] = strongLeg;
    }

    const handelEditProfile = async (event) => {
        event.preventDefault();
        setError("");
        setIsLoading(true);
        if (image) {
            updateUserProfilePic(user?.id, image, user?.dp);
        }
        updateUserProfile(user.id, updateFields);
        setIsLoading(false);
        onClose();
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                }}
            >
                <Box sx={{
                    filter: isLoading ? 'blur(4px)' : 'none',
                }}>
                    <Box
                        sx={{
                            p: 2,
                            bgcolor: "background.paper",
                            boxShadow: 2,
                            position: "sticky",
                            top: 0,
                            zIndex: 1
                        }}
                    >
                        <Typography variant="h6">Edit Profile</Typography>
                    </Box>

                    {/* Scrollable Content */}
                    <Box
                        sx={{
                            maxHeight: "calc(100vh - 100px)", // Adjust height as needed
                            overflowY: "auto",
                            p: 2
                        }}
                    >
                        <form onSubmit={handelEditProfile}>
                            <TextField
                                fullWidth
                                label="Nationality"
                                margin="normal"
                                value={nationality}
                                onChange={(e) => setNationality(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                label="Date of Birth "
                                margin="normal"
                                value={dateOfBirth}
                                onChange={(e) => setDOB(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                label="Place of Birth"
                                margin="normal"
                                value={placeofBirth}
                                onChange={(e) => setPOB(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                label="Height"
                                margin="normal"
                                value={height}
                                onChange={(e) => setHeight(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                label="Weight"
                                margin="normal"
                                value={weight}
                                onChange={(e) => setWeight(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                label="Description"
                                margin="normal"
                                multiline={true}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                label="Injury History"
                                margin="normal"
                                multiline={true}
                                value={injuryHistory}
                                onChange={(e) => setInjuryHistory(e.target.value)}
                            />
                            {user?.Category === "Football" && (
                                <>
                                    <TextField
                                        fullWidth
                                        label="Position"
                                        margin="normal"
                                        value={position}
                                        onChange={(e) => setPosition(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Strong Leg"
                                        margin="normal"
                                        value={strongLeg}
                                        onChange={(e) => setStrongLeg(e.target.value)}
                                    />
                                </>
                            )}

                            <div style={{ margin: "normal", textAlign: "center" }}>
                                <img
                                    src={profilePicUrl}
                                    alt="Profile"
                                    style={{ width: "100px", height: "100px" }}
                                />
                                <input
                                    type="file"
                                    onChange={onImageChange}
                                    accept="image/*"
                                    style={{ display: "block", margin: "auto" }}
                                />
                            </div>

                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                type="submit"
                                style={{ marginTop: "16px" }}
                            >
                                Save
                            </Button>
                        </form>
                    </Box>
                </Box>
                {isLoading && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "rgba(255, 255, 255, 0.8)"
                        }}
                    >
                        <CircularProgress /> {/* Material-UI loader */}
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default EditProfile;
