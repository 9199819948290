import React from 'react';
import {
  Box,
  Typography,
  Button, List, ListItem
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'; 


export const AgeGroupModal = ({ onClose, updateSelectedAgeGroups, selectedAgeGroup }) => {
  const ageGroups = ['5-10','11-15','15-20','21-24', '25-30'];
  const handleSelect = (ageGroup) => {
    updateSelectedAgeGroups(ageGroup);
  };

  return (
    <Box className="filter-modal">
      <Box className="modal-content">
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          backgroundColor: 'inherit',
          zIndex: 1,
          padding: '0.5rem'
        }}>
          <Typography variant="h6" sx={{ mb: 0, flexGrow: 1 }}>
            Age Groups
          </Typography>
          <Box
            onClick={onClose}
          >
            <CloseIcon />
          </Box>
        </Box>
        <Box className="scrollable-list">
          <List>
            <ListItem
              onClick={() => handleSelect("All Ages")}
              style={{
                backgroundColor: selectedAgeGroup === "All Ages" ? '#0056b3' : 'transparent',
                color: selectedAgeGroup === "All Ages" ? 'white' : 'inherit',
                cursor: 'pointer',
                borderRadius: '4px',
                margin: '4px 0', // Optional spacing
              }}
            >
              All Ages
            </ListItem>
            {ageGroups.map((ageGroup) => (
              <ListItem
                key={ageGroup}
                onClick={() => handleSelect(ageGroup)}
                style={{
                  backgroundColor: selectedAgeGroup === ageGroup ? '#0056b3' : 'transparent',
                  color: selectedAgeGroup === ageGroup ? 'white' : 'inherit',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  margin: '4px 0', // Optional spacing
                }}
              >
                {ageGroup}
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};
