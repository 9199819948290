import React from 'react';
import {
  Box,
  Typography,
  List, ListItem
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export const CategoryModal = ({ onClose, categories, updateSelectedCategories, selectedCategory }) => {
  const handleSelect = (ageGroup) => {
    updateSelectedCategories(ageGroup);
  };

  return (
    <Box className="filter-modal">
      <Box className="modal-content">
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          backgroundColor: 'inherit',
          zIndex: 1,
          padding: '0.5rem'
        }}>
          <Typography variant="h6" sx={{ mb: 0, flexGrow: 1 }}>
            Categories
          </Typography>
          <Box
            onClick={onClose}
          >
            <CloseIcon />
          </Box>
        </Box>
        <Box className="scrollable-list">
          <List>
            <ListItem
              onClick={() => handleSelect("All Categories")}
              style={{
                backgroundColor: selectedCategory === "All Categories" ? '#0056b3' : 'transparent',
                color: selectedCategory === "All Categories" ? 'white' : 'inherit',
                cursor: 'pointer',
                borderRadius: '4px',
                margin: '4px 0', // Optional spacing
              }}
            >
              All Categories
            </ListItem>
            {categories.map((category) => (
              <ListItem
                key={category}
                onClick={() => handleSelect(category.title)}
                style={{
                  backgroundColor: selectedCategory === category.title ? '#0056b3' : 'transparent',
                  color: selectedCategory === category.title ? 'white' : 'inherit',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  margin: '4px 0', // Optional spacing
                }}
              >
                {category.title}
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};
