import React, { useState } from "react";
import {
    Box,
    IconButton,
    Typography,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import { Menu, Close } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import { useNavigate } from "react-router-dom";
import AuthModal from "components/shared/modals/AuthModal";

import logo from "images/logo.png"

import { useAuth } from "contexts/AuthContext";
import { useApp } from "contexts/AppContext";
import NavLinks from "./ui/NavLinks";

const Navbar = () => {
    const { authUserID } = useAuth()
    const { getUserById } = useApp()

    const navigate = useNavigate();
    const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);

    const isNonMobileScreens = useMediaQuery("(min-width: 1022px)");
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [userName, setUserName] = useState("")

    const handleLoginClick = () => {
        setIsLoginModalOpen(true);
    };

    const handleModalClose = () => {
        setIsLoginModalOpen(false);
    };

    const theme = useTheme();
    const primaryLight = theme.palette.primary.light;
    const alt = "#006B7D";


    React.useEffect(() => {
        const fetchData = async () => {
            const userData = await getUserById(authUserID);
            if (userData) {
                setUserName(userData.userName);
            }
        };
        fetchData();
    }, [authUserID]);

    return (
        <Box
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                zIndex: 100
            }}
        >
            <FlexBetween padding={isNonMobileScreens ? "1.5rem 12rem" : "0.5rem"} backgroundColor={alt}>
                <FlexBetween gap="1.5rem">
                    <img src={logo} alt="Logo" style={{ height: '2.5rem' }} />
                </FlexBetween>
                {isNonMobileScreens
                    ? <FlexBetween gap="2rem">
                        <NavLinks />
                    </FlexBetween>
                    : <IconButton
                        style={{ marginLeft: "1rem" }}
                        onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
                    >
                        <Menu color="primary" />
                    </IconButton>}
            </FlexBetween>
            <AuthModal isOpen={isLoginModalOpen} onClose={handleModalClose} />


            {/* MOBILE NAV */}
            {!isNonMobileScreens &&
                isMobileMenuToggled &&
                <Box
                    position="fixed"
                    right="0"
                    bottom="0"
                    height="100%"
                    zIndex="10"
                    maxWidth="500px"
                    minWidth="300px"
                    backgroundColor="white"
                >
                    {/* CLOSE ICON */}
                    <Box display="flex" justifyContent="flex-end" p="1rem">
                        <IconButton
                            onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
                        >
                            <Close />
                        </IconButton>
                    </Box>

                    {/* MENU ITEMS */}
                    <FlexBetween
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        gap="3rem"
                    >
                        <Typography
                            fontSize="clamp(0.6rem, 1rem, 1.5rem)"
                            color="#00000"
                            onClick={() => { navigate('/'); setIsMobileMenuToggled(!isMobileMenuToggled) }}
                            sx={{
                                "&:hover": {
                                    color: primaryLight,
                                    cursor: "pointer"
                                }
                            }}
                        >
                            Home
                        </Typography>
                        <Typography
                            fontSize="clamp(0.6rem, 1rem, 1.5rem)"
                            color="#00000"
                            onClick={() => { navigate('/videos'); setIsMobileMenuToggled(!isMobileMenuToggled) }}
                            sx={{
                                "&:hover": {
                                    color: primaryLight,
                                    cursor: "pointer"
                                }
                            }}
                        >
                            Videos
                        </Typography>
                        <Typography
                            fontSize="clamp(0.6rem, 1rem, 1.5rem)"
                            color="#00000"
                            onClick={() => { navigate("/cards"); setIsMobileMenuToggled(!isMobileMenuToggled) }}
                            sx={{
                                "&:hover": {
                                    color: primaryLight,
                                    cursor: "pointer"
                                }
                            }}
                        >
                            Cards
                        </Typography>
                    </FlexBetween>
                </Box>}
        </Box>
    );
};

export default Navbar;
