import React from 'react';

const UserInfo = ({ label, info }) => (
    <div className="infoBox">
        <p className="infoLabel">{label}</p>
        <p className="infoData">{info}</p>
    </div>
);

export default UserInfo;
