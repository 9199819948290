import React, { useState } from 'react';
import { FormControl, InputLabel, Input, Modal, Box, Button, Typography, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';


import CloseIcon from '@mui/icons-material/Close';

import { useAuth } from 'contexts/AuthContext';
import { useApp } from 'contexts/AppContext';
import FlexBetween from '../../FlexBetween';

const AddVideo = ({ isOpen, onClose, userID }) => {

    const [image, setImage] = useState(null);
    const [video, setVideo] = useState(null);
    const [videoCover, setVideoCover] = useState();
    const [videoLink, setVideoLink] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [uploadOption, setUploadOption] = useState('');
    const [skills, setSkills] = useState([])
    const [selectedSkill, setSelectedSkill] = useState('');

    const { uploadVideo, getUserById, getVideosSkills } = useApp()
    

    React.useEffect(() => {
        async function fetchData() {
            try {
                const user = await getUserById(userID);
                const videoSkills = await getVideosSkills(user.Category);
                const allSkills = videoSkills.flatMap(item => item.skills);
                console.log(allSkills)
                setSkills(allSkills);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        }
    
        fetchData();
    }, [userID]);

    const handleUploadOptionChange = (option) => {
        setUploadOption(option);
    };

    const onImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setVideoCover(file);
            setImage(URL.createObjectURL(file));
        }
    }

    const handleFileInputClick = (inputId) => {
        const input = document.getElementById(inputId);
        input && input.click();
    };

    const onVideoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setVideo(file);
        }
    }

    const clearAll = () => {
        setImage(null);
        setVideo(null);
        setVideoCover(null)
        setVideoLink('')
        setUploadOption('')
        setSelectedSkill('')
    }

    const handleClose = () => {
        clearAll()
        onClose()
    }


    const handelAddVideo = async (e) => {
        e.preventDefault();

        if ((!videoCover && !videoLink)) {
            alert("Please either upload a video or provide a video link.");
            return;
        }

        if (selectedSkill === '') {
            alert("Please select a skill you are uploading a video for");
            return;
        }

        if (video) {
            setIsLoading(true)
            await uploadVideo(userID, videoCover, video, selectedSkill)
            setIsLoading(false)
        } else {
            setIsLoading(true)
            console.log(selectedSkill)
            await uploadVideo(userID, videoCover, videoLink, selectedSkill)
            setIsLoading(true)
        }

        onClose()
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
            }}>
                <Box sx={{
                    filter: isLoading ? 'blur(4px)' : 'none',
                }}>
                    <FlexBetween sx={{
                        p: 2,
                        bgcolor: 'background.paper',
                        boxShadow: 2,
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        display: 'flex'
                    }}>
                        <Typography variant="h6">Add Video</Typography>
                        <Box
                            onClick={() => handleClose()}
                        >
                            <CloseIcon />
                        </Box>
                    </FlexBetween>
                    <Box sx={{
                        maxHeight: 'calc(100vh - 100px)',
                        overflowY: 'auto',
                        p: 2,
                    }}>
                        <form onSubmit={handelAddVideo}>
                        <select
                            className="custom-dropdown"
                            value={selectedSkill}
                            onChange={e => setSelectedSkill(e.target.value)}
                        >
                            <option value="" disabled>Select a skill</option>
                             {
                                skills && skills.map(skill => (
                                    <option key={skill} value={skill}>
                                    {skill}
                                    </option>
                                ))
                             }
                        </select>
                            <FormControl fullWidth style={{ margin: '8px 0' }}>
                                <Input
                                    id="video-cover-upload"
                                    type="file"
                                    onChange={onImageChange}
                                    inputProps={{ accept: "image/*" }}
                                    disableUnderline
                                    style={{ display: 'none' }}
                                />
                                <Button variant="outlined" component="span" style={{ marginTop: '8px' }} onClick={() => handleFileInputClick('video-cover-upload')}>
                                    Upload Cover
                                </Button>
                                {image && (
                                    <img src={image} alt="Video cover" style={{ width: '100px', height: '100px', marginTop: '8px' }} />
                                )}
                            </FormControl>

                            <div style={{ margin: 'normal', textAlign: 'center' }}>
                                <Button
                                    variant={uploadOption === 'file' ? "contained" : "outlined"}
                                    color="primary"
                                    onClick={() => handleUploadOptionChange('file')}
                                    style={{ marginRight: '8px' }}
                                >
                                    Upload Video
                                </Button>
                                <Button
                                    variant={uploadOption === 'link' ? "contained" : "outlined"}
                                    color="primary"
                                    onClick={() => handleUploadOptionChange('link')}
                                >
                                    Type Video Link
                                </Button>
                            </div>
                            {uploadOption === 'link' && (
                                <FormControl fullWidth style={{ margin: '8px 0' }}>
                                    <TextField
                                        label="Video Link"
                                        placeholder="http://"
                                        value={videoLink}
                                        onChange={(e) => setVideoLink(e.target.value)}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </FormControl>)}
                            {uploadOption === 'file' && (
                                <FormControl fullWidth style={{ margin: '8px 0' }}>
                                    <InputLabel htmlFor="video-upload">Video File</InputLabel>
                                    <Input
                                        id="video-upload"
                                        type="file"
                                        onChange={onVideoChange}
                                        inputProps={{ accept: "video/*" }}
                                        disableUnderline
                                        style={{ display: 'none' }}
                                    />
                                    <Button variant="outlined" component="span" style={{ marginTop: '8px' }} onClick={() => handleFileInputClick('video-upload')}>
                                        Upload Cover
                                    </Button>
                                </FormControl>)}

                            <Button fullWidth variant="contained" color="primary" type="submit" style={{ marginTop: '16px' }}>
                                Save
                            </Button>
                        </form>
                    </Box>
                </Box>
                {isLoading && (
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)'
                    }}>
                        <CircularProgress /> {/* Material-UI loader */}
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default AddVideo;
