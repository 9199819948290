import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import { themeSettings } from "theme";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { auth } from './config'
import { AuthContextProvider } from "contexts/AuthContext";
import { AppContextProvider } from "contexts/AppContext";
import AppLayout from "layout";
import HomeScreen from "screens/home";
import VideosScreen from "screens/videos";
import CardsScreen from "screens/cards";
import ProfileScreen from "screens/profile";
import Player from "screens/video-player";

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode])
  const [userID, setUserID] = React.useState(null)

  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
      setUserID(uid)
      // ...
    } else {
      setUserID(null)
    }
  });

  return (
    <div className="app">
      <BrowserRouter>
        <AppContextProvider>
          <AuthContextProvider>
          <ThemeProvider theme={theme}>
            <Routes>
              <Route element={<AppLayout />}>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/videos" element={<VideosScreen />} />
                <Route path="/videos/:videoId" element={<Player />} />
                <Route path="/cards" element={<CardsScreen />} />
                <Route path="/profile/:userID" element={<ProfileScreen />} />
              </Route>
            </Routes>
            </ThemeProvider>
          </AuthContextProvider>
        </AppContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
