import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import review from "images/review.png"


export const ReviewSection = () => {
    const isMobile = useMediaQuery("(max-width:960px)");
    return (
        <Box
            marginTop={'3.5rem'}
        >
            <Box
                display="flex"
                flexDirection={isMobile ? "column" : "row"}
            >
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    textAlign={'left'}
                    alignSelf={'center'}
                    maxWidth={isMobile ? "100%" : "55%"}
                    mb={isMobile ? "2rem" : "0"}
                    marginTop={isMobile ? "3.5rem" : "0"}
                >
                    <Typography
                        variant={isMobile ? "h4" : "h2"}
                        gutterBottom
                        fontSize={isMobile ? '1.5rem' : '3rem'}
                        fontFamily="Rubik, sans-serif"
                        color={'#00A0BC'}
                        marginBottom={isMobile ? '2.5rem' : undefined}
                    >
                        Review and Rate Performances
                    </Typography>
                    <Box display={'flex'} flexDirection={'column'} gap={"1rem"}>
                        <Box>
                            <Typography
                                fontSize="22px"
                                fontFamily="Rubik"
                                style={{
                                    marginBottom: '0.5rem',
                                }}
                            >
                                Share Your Feedback
                            </Typography>
                            <Typography
                                fontFamily="Rubik"
                                fontSize="16px"
                                color="#7A788E"
                                style={{
                                    flexGrow: 1,
                                    maxWidth: isMobile ? undefined : '65%'
                                }}
                            >
                                Leave constructive comments and ratings to help athletes improve and receive valuable insights.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                fontSize="22px"
                                fontFamily="Rubik"
                                style={{
                                    marginBottom: '0.5rem',
                                }}
                            >
                                Track Leaderboards
                            </Typography>
                            <Typography
                                fontFamily="Rubik"
                                fontSize="16px"
                                color="#7A788E"
                                style={{
                                    flexGrow: 1,
                                    maxWidth: isMobile ? undefined : '65%'
                                }}
                            >
                                Stay updated on the latest rankings and see who's making waves in the sports talent community.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                fontSize="22px"
                                fontFamily="Rubik"
                                style={{
                                    marginBottom: '0.5rem',
                                }}
                            >
                                Engage With Peers
                            </Typography>
                            <Typography
                                fontFamily="Rubik"
                                fontSize="16px"
                                color="#7A788E"
                                style={{
                                    flexGrow: 1,
                                    maxWidth: isMobile ? undefined : '65%'
                                }}
                            >
                                Connect with fellow sports enthusiasts, share your thoughts, and discuss the latest talent discoveries.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    width={isMobile ? "60%" : "50%"}
                    height={isMobile ? '400px' : '500px'}
                    display="flex"
                    alignSelf={isMobile ? 'center' : 'flex-end'}
                >
                    <img
                        src={review}
                        alt="browse talent"
                        style={{ width: "100%", borderRadius: isMobile ? "0" : "10px" }}
                    />
                </Box>
            </Box>
        </Box>
    )
}


