import React, { useState } from 'react';
import {
 Box, Avatar, Typography, Rating, TextField, Button, useMediaQuery, Stack, Divider, List, ListItem, ListItemAvatar, ListItemText
} from '@mui/material';
import { Share, Download, Send } from '@mui/icons-material';
import ReactPlayer from 'react-player';
import { styled } from '@mui/material/styles';
import { useApp } from 'contexts/AppContext';
import { useVideosContext } from 'contexts/use-video-context';
import { useParams } from "react-router-dom";
import { Reviews } from "dummData";

const StyledReactPlayer = styled(ReactPlayer)({
    borderRadius: '4px',
    overflow: 'hidden',
});

export default function Player() {
    const { videoId } = useParams();
    const { getVideoById } = useVideosContext()
    const [video, setVideo] = useState()
    const { reviewVideo } = useApp()
    const [rating, setRating] = useState(0);
    const [reviewText, setReviewText] = useState('');
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    const [iRated, setIRated] = useState(false)

    const isMine = false

    const handleRatingChange = (event, newValue) => {
        setRating(newValue);
    };

    const handleReviewChange = (event) => {
        setReviewText(event.target.value);
    };

    const handleSubmitReview = async () => {
        const myRating = localStorage.getItem('myrating');
        if(myRating){
            alert("You have already rated this video!!!")
        } else {
            await reviewVideo(videoId, reviewText, rating)
            localStorage.setItem('myrating', rating);
        }
        setRating("");
        setReviewText('');
    };

    React.useEffect(() => {
        const fetchData = async () => {
            const videoDate = await getVideoById(videoId);
            if (videoDate) {
                setVideo(videoDate);
            }
        };
        fetchData();
    }, [videoId]);

    if (!video) return null

    return (
        <Box display={'flex'}
            flexDirection={'column'}
            padding={!isMobile ? "8rem 15rem" : "5rem 1rem"}
            gap={isMobile ? '1rem' : '2rem'}
        >
            <StyledReactPlayer url={video.videoSrc} controls width="100%" height={isMobile ? '300px' : '500px'} />

            <Box my={2} display="flex" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar src={video.profilePic} />
                    <Typography variant="subtitle">{video.userName}</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                   <Rating value={4.3} size='large' />
                </Stack>
            </Box>
            <Box my={2} marginTop={'-1.5rem'} display="flex" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="subtitle1">Category:</Typography>
                    <Typography variant="subtitle1">{video.Category}</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <Button startIcon={<Share />} size="small">Share</Button>
                    <Button startIcon={<Download />} size="small">Download</Button>
                </Stack>
            </Box>

            <Divider />

            {!isMine && <Box display={'flex'} flexDirection={'column'} gap={'1rem'} mt={2}>
                <Typography variant="subtitle1" gutterBottom>Rate and Review</Typography>
                <Stack direction="column" spacing={2}>
                    <TextField
                        size="small"
                        placeholder="Write a review"
                        variant="outlined"
                        value={reviewText}
                        onChange={handleReviewChange}
                        fullWidth
                        multiline
                        rows={1}
                        margin="dense"
                    />
                     <Rating value={rating} onChange={handleRatingChange} />
                    <Button
                        variant="contained"
                        endIcon={<Send />}
                        onClick={handleSubmitReview}
                        sx={{
                            width: '5rem'
                        }}
                    >
                        Send
                    </Button>
                </Stack>
            </Box>
            }
            <Divider />

            <Typography marginBottom={'-1rem'} variant="subtitle1" gutterBottom>Reviews and Ratings</Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {Reviews.map((review, index) => (
                    <React.Fragment key={review.id}>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar alt={review.userName} src={'https://vdostavka.ru/wp-content/uploads/2019/05/no-avatar.png'} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={review.userName}
                                secondary={<Typography variant="body2" color="text.primary">{review.text}</Typography>}
                            />
                            <Rating value={review.rating} onChange={handleRatingChange} />
                        </ListItem>
                        {index < Reviews.length - 1 && <Divider variant="inset" component="li" />}
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );
};
