import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";


const faq = [
    { question: 'Is it fully online?', answer: 'Yes, fully online. A ChooseMeSport scout watches your video and tells you which club you might be invited to for a live tryout if the video makes a good impression.' },
    { question: 'What type of video do I have to send to the scout?', answer: 'You just have to upload a clip of your best action on your profile and we will do the rest. The video must not be longer than 2 minutes and in high quality.' },
    { question: 'How will I know if the scout watched me?', answer: 'You will receive an email from our team with a brief review of the video and we will let you know which team you can be referred to if you made a good impression.' },
    { question: 'Can I connect with multiple scouts?', answer: 'Yes, our platform allows you to connect with multiple scouts from both collegiate and professional teams to maximize your exposure.' },
    { question: 'How can I improve my visibility and ratings?', answer: 'To improve visibility and ratings, engage with the community, and consistently showcase your skills through high-quality videos and interactions.' },
    { question: 'To which countries can I be invited to the trial?', answer: 'You can get trial drafts at clubs in Netherlands, Portugal, Sweden, Spain, England, Finland, Poland and other countries around the globe.' },

];

export const FAQ = () => {
    const isMobile = useMediaQuery("(max-width:960px)");
    return (
        <Box marginTop={'2rem'} display={'flex'} flexDirection={'column'}>
            <Typography
                variant={isMobile ? "h4" : "h2"}
                gutterBottom
                fontSize={isMobile ? '1.5rem' : '3rem'}
                fontFamily="Rubik, sans-serif"
                color={'#00A0BC'}
                marginBottom={isMobile ? '2.5rem' : undefined}
            >
                Frequently Asked Questions
            </Typography>

            {faq.map((faq, index) => (
                <Box display={'flex'} marginBottom={'1.5rem'} flexDirection={isMobile ? 'column' : 'row'} gap={'1rem'}>
                    <Box
                        display={'flex'}
                        style={{
                            height: '40px',
                            width: '40px',
                            backgroundColor: '#00A0BC',
                            borderRadius: '8px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography
                            fontSize="26px"
                            fontFamily="Rubik"
                            color={'#FFFFFF'}
                        >
                            {index + 1}
                        </Typography>

                    </Box>
                    <Box>
                        <Typography
                            fontSize="22px"
                            fontFamily="Rubik"
                            style={{
                                marginBottom: '0.5rem',
                            }}
                            color={'#00A0BC'}
                        >
                            {faq.question}
                        </Typography>
                        <Typography
                            fontFamily="Rubik"
                            fontSize="16px"
                            color="#7A788E"
                        >
                            {faq.answer}
                        </Typography>
                    </Box>

                </Box>
            ))}


        </Box>
    )
}


