import React, { createContext, useState, useContext } from 'react';
import { onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { addDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from "../config"
import { useApp } from './AppContext';

const AuthContext = createContext();

const userProfile = {
    dp: "https://vdostavka.ru/wp-content/uploads/2019/05/no-avatar.png",
    "Date of Birth": "",
    "Place of Birth": "",
    "Nationality": "",
    "Height": "",
    "Weight": "",
    "Position": "",
    "Squad number": "",
    "Strong leg": "",
    "Description": "",
    "Injury history": ""
}

export const AuthContextProvider = ({ children }) => {
    const [authUserID, setAuthUserID] = useState(null)
    const { getSkillsBySport } = useApp()

    onAuthStateChanged(auth, (user) => {
        if (user) {
            const uid = user.uid;
            setAuthUserID(uid)
            // ...
        } else {
            setAuthUserID(null)
        }
    });


    const signUp = async (email, password, username, name, category, ageGroup) => {
        await createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                const user = userCredential.user;
                await createDbUser(user.uid, name, username, category, ageGroup)
            })
            .then(() => {
                alert("Account succesfully created");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert("Error creating user and profile", errorCode, errorMessage);
            });
    }

    const createDbUser = async (id, name, username, category, ageGroup) => {
        try {
            const skills = getSkillsBySport(category);
            
            await addDoc(collection(db, "Users"), {
                sub: id,
                Name: name,
                userName: username,
                Category: category,
                ageGroup: ageGroup,
                ...userProfile,
                skills: skills
            });
        } catch (error) {
            alert(error.message);
        }
    }

    const signUserIn = async (email, password) => {
        try {
            await signInWithEmailAndPassword(auth, email, password)
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    alert(errorMessage)
                });
        } catch (error) {
            alert(error.message);
        }
    };

    const signUserOut = async () => {
        try {
            await signOut(auth);
            setAuthUserID(null)
        } catch (error) {
            alert(error.message);
        }
    };


    return (
        <AuthContext.Provider value={{ authUserID, signUp, signUserIn, signUserOut }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthContextProvider');
    }
    return context;
};
