export const videoData = [
    {
        id: 1,
        videoSrc: "https://www.youtube.com/watch?v=QqxPau7fqwE&ab_channel=limit%E2%9A%95%EF%B8%8F",
        poster: "https://mldxmiy8rz9p.i.optimole.com/cb:v4og.11a76/w:auto/h:auto/q:mauto/f:avif/https://karambafc.com/wp-content/uploads/2023/04/creative-collage-young-male-soccer-player-ball-over-dark-background-concept-professional-sport-sport-poster-creative-252584716-removebg-preview-1-1.png",
        profilePic: "path_to_profile_1.jpg",
        userName: "User 1",
        rating: "5",
    },
    {
        id: 2,
        videoSrc: "https://www.youtube.com/watch?v=QqxPau7fqwE&ab_channel=limit%E2%9A%95%EF%B8%8F",
        profilePic: "path_to_profile_1.jpg",
        userName: "User 2",
        poster: "https://as2.ftcdn.net/v2/jpg/00/64/74/67/1000_F_64746786_calRQlzPXs0GRU4cK1LslFBhzu1grx3K.jpg",
        rating: 4,
    },
    {
        id: 3,
        videoSrc: "https://www.youtube.com/watch?v=QqxPau7fqwE&ab_channel=limit%E2%9A%95%EF%B8%8F",
        poster: "https://hips.hearstapps.com/vidthumb/brightcove/565750d47765627952000062/thumb_1448562909.png?crop=1xw:1xh;center,top",
        profilePic: "path_to_profile_1.jpg",
        userName: "User 3",
        rating: 3,
    },
    {
        id: 4,
        videoSrc: "https://www.youtube.com/watch?v=QqxPau7fqwE&ab_channel=limit%E2%9A%95%EF%B8%8F",
        poster: "https://mldxmiy8rz9p.i.optimole.com/cb:v4og.11a76/w:auto/h:auto/q:mauto/f:avif/https://karambafc.com/wp-content/uploads/2023/04/creative-collage-young-male-soccer-player-ball-over-dark-background-concept-professional-sport-sport-poster-creative-252584716-removebg-preview-1-1.png",
        profilePic: "path_to_profile_1.jpg",
        userName: "User 1",
        rating: "5",
    },
    {
        id: 5,
        videoSrc: "https://www.youtube.com/watch?v=QqxPau7fqwE&ab_channel=limit%E2%9A%95%EF%B8%8F",
        profilePic: "path_to_profile_1.jpg",
        userName: "User 2",
        poster: "https://as2.ftcdn.net/v2/jpg/00/64/74/67/1000_F_64746786_calRQlzPXs0GRU4cK1LslFBhzu1grx3K.jpg",
        rating: 4,
    },
    {
        id: 6,
        videoSrc: "https://www.youtube.com/watch?v=QqxPau7fqwE&ab_channel=limit%E2%9A%95%EF%B8%8F",
        poster: "https://hips.hearstapps.com/vidthumb/brightcove/565750d47765627952000062/thumb_1448562909.png?crop=1xw:1xh;center,top",
        profilePic: "path_to_profile_1.jpg",
        userName: "User 3",
        rating: 3,
    },
    {
        id: 7,
        videoSrc: "https://www.youtube.com/watch?v=QqxPau7fqwE&ab_channel=limit%E2%9A%95%EF%B8%8F",
        poster: "https://mldxmiy8rz9p.i.optimole.com/cb:v4og.11a76/w:auto/h:auto/q:mauto/f:avif/https://karambafc.com/wp-content/uploads/2023/04/creative-collage-young-male-soccer-player-ball-over-dark-background-concept-professional-sport-sport-poster-creative-252584716-removebg-preview-1-1.png",
        profilePic: "path_to_profile_1.jpg",
        userName: "User 1",
        rating: "5",
    },
    {
        id: 8,
        videoSrc: "https://www.youtube.com/watch?v=QqxPau7fqwE&ab_channel=limit%E2%9A%95%EF%B8%8F",
        profilePic: "path_to_profile_1.jpg",
        userName: "User 2",
        poster: "https://as2.ftcdn.net/v2/jpg/00/64/74/67/1000_F_64746786_calRQlzPXs0GRU4cK1LslFBhzu1grx3K.jpg",
        rating: 4,
    },
    {
        id: 9,
        videoSrc: "https://www.youtube.com/watch?v=QqxPau7fqwE&ab_channel=limit%E2%9A%95%EF%B8%8F",
        poster: "https://hips.hearstapps.com/vidthumb/brightcove/565750d47765627952000062/thumb_1448562909.png?crop=1xw:1xh;center,top",
        profilePic: "path_to_profile_1.jpg",
        userName: "User 3",
        rating: 3,
    }, {
        id: 10,
        videoSrc: "https://www.youtube.com/watch?v=QqxPau7fqwE&ab_channel=limit%E2%9A%95%EF%B8%8F",
        poster: "https://mldxmiy8rz9p.i.optimole.com/cb:v4og.11a76/w:auto/h:auto/q:mauto/f:avif/https://karambafc.com/wp-content/uploads/2023/04/creative-collage-young-male-soccer-player-ball-over-dark-background-concept-professional-sport-sport-poster-creative-252584716-removebg-preview-1-1.png",
        profilePic: "path_to_profile_1.jpg",
        userName: "User 1",
        rating: "5",
    },
    {
        id: 11,
        videoSrc: "https://www.youtube.com/watch?v=QqxPau7fqwE&ab_channel=limit%E2%9A%95%EF%B8%8F",
        profilePic: "path_to_profile_1.jpg",
        userName: "User 2",
        poster: "https://as2.ftcdn.net/v2/jpg/00/64/74/67/1000_F_64746786_calRQlzPXs0GRU4cK1LslFBhzu1grx3K.jpg",
        rating: 4,
    },
    {
        id: 12,
        videoSrc: "https://www.youtube.com/watch?v=QqxPau7fqwE&ab_channel=limit%E2%9A%95%EF%B8%8F",
        poster: "https://hips.hearstapps.com/vidthumb/brightcove/565750d47765627952000062/thumb_1448562909.png?crop=1xw:1xh;center,top",
        profilePic: "path_to_profile_1.jpg",
        userName: "User 3",
        rating: 3,
    },
];

export const adData = [
    {
        id: 1,
        adImageSrc: "https://as2.ftcdn.net/v2/jpg/00/64/74/67/1000_F_64746786_calRQlzPXs0GRU4cK1LslFBhzu1grx3K.jpg",
    },
    {
        id: 2,
        adImageSrc: "https://as2.ftcdn.net/v2/jpg/00/64/74/67/1000_F_64746786_calRQlzPXs0GRU4cK1LslFBhzu1grx3K.jpg",
    },
    {
        id: 3,
        adImageSrc: "https://as2.ftcdn.net/v2/jpg/00/64/74/67/1000_F_64746786_calRQlzPXs0GRU4cK1LslFBhzu1grx3K.jpg",
    },
    {
        id: 4,
        adImageSrc: "https://as2.ftcdn.net/v2/jpg/00/64/74/67/1000_F_64746786_calRQlzPXs0GRU4cK1LslFBhzu1grx3K.jpg",
    },
];


export const user = {
    id: 1,
    dp: "https://manunitedcore.com/wp-content/uploads/2021/09/Ronaldo-Profile.png",
    info: {
        "Name": "Abubakar Shehu Umar",
        "Date of Birth": "22.12.2000",
        "Place of Birth": "Damagum",
        "Nationality": "Nigeria",
        "Category": "Football",
        "Height": "169 cm",
        "Weight": "67 Kg",
        "Position": "Striker",
        "Squad number": "10",
        "Strong leg": "Right",
        "username": "Asusoftrr"
    },
    skills: {
        "Pace": 92,
        "Shooting": 95,
        "Passing": 81,
        "Dribbling": 89,
        "Defending": 35,
        "Physicality": 85,
        "Team Work": 90,
        "Ball Controll": 70,
        "Heading": 80
    }

}


export const Reviews = [
    {
        id: '1',
        userName: 'Asusoft',
        userImage: "https://manunitedcore.com/wp-content/uploads/2021/09/Ronaldo-Profile.png",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
        rating: 4.5
    },
    {
        id: '2',
        userName: 'Asusoft',
        userImage: "https://manunitedcore.com/wp-content/uploads/2021/09/Ronaldo-Profile.png",
        text: "Go man sky is you limit",
        rating: 4
    },
    {
        id: '3',
        userName: 'Asusoft',
        userImage: "https://manunitedcore.com/wp-content/uploads/2021/09/Ronaldo-Profile.png",
        text: "Go man sky is you limit",
        rating: 3
    },
    {
        id: '4',
        userName: 'Asusoft',
        userImage: "https://manunitedcore.com/wp-content/uploads/2021/09/Ronaldo-Profile.png",
        text: "Go man sky is you limit",
        rating: 5
    },
    {
        id: '5',
        userName: 'Asusoft',
        userImage: "https://manunitedcore.com/wp-content/uploads/2021/09/Ronaldo-Profile.png",
        text: "Go man sky is you limit",
        rating: 3.5
    },

]
