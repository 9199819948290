import React, { useState } from 'react';
import { Box, Typography, IconButton, TextField, Button } from '@mui/material';
import { Instagram, Facebook, Twitter, YouTube } from '@mui/icons-material'
import { useApp } from 'contexts/AppContext';

const ContactUsSection = () => {
    const {contactUs} = useApp()
    const [name, setName] = useState('')
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")


    const clearAll = () => {
        setName("")
        setEmail("")
        setMessage("")
    }

    const handleCOntactUs =async (event) => {
        event.preventDefault();
        await contactUs(name, email, message);
        clearAll()
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding="4rem"
            style={{ backgroundColor: "#f8f8f8" }} // Light grey background
        >
            <Typography variant="h3" gutterBottom>
                Contact Us
            </Typography>

            <Typography variant="body1" paragraph>
                Have questions? We're here to help. Connect with us on social media or send us a message directly.
            </Typography>
            <form onSubmit={handleCOntactUs} style={{ width: '100%', maxWidth: '400px' }}>
                <Box display="flex" flexDirection="column" gap="1rem" width="100%">
                    <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        required
                        value={name}
                            onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        label="Email"
                        variant="outlined"
                        type="email"
                        fullWidth
                        required
                        value={email}
                            onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        label="Message"
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                        required
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                    >
                        Send Message
                    </Button>
                </Box>
            </form>
            <Box display="flex" marginTop="2rem" justifyContent="center" gap="1rem">
                <IconButton
                    onClick={() => window.open("https://www.instagram.com", "_blank")}
                    color="primary"
                >
                    <Instagram fontSize="large" />
                </IconButton>

                <IconButton
                    onClick={() => window.open("https://www.facebook.com", "_blank")}
                    color="primary"
                >
                    <Facebook fontSize="large" />
                </IconButton>

                <IconButton
                    onClick={() => window.open("https://www.twitter.com", "_blank")}
                    color="primary"
                >
                    <Twitter fontSize="large" />
                </IconButton>

                <IconButton
                    onClick={() => window.open("https://www.youtube.com", "_blank")}
                    color="primary"
                >
                    <YouTube fontSize="large" />
                </IconButton>
            </Box>
        </Box>
    );
};

export default ContactUsSection;
